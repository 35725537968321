// Generated by Framer (6f17e57)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {g2b4QUCpC: {hover: true, pressed: true}};

const cycleOrder = ["g2b4QUCpC"];

const variantClassNames = {g2b4QUCpC: "framer-v-1eafk6o"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "g2b4QUCpC", image: pbrU38Tu3 = {src: new URL("assets/512/YCLJJn6g2ELAeTfIx44br9eGcM0.png", import.meta.url).href, srcSet: `${new URL("assets/512/YCLJJn6g2ELAeTfIx44br9eGcM0.png", import.meta.url).href} 512w, ${new URL("assets/YCLJJn6g2ELAeTfIx44br9eGcM0.png", import.meta.url).href} 564w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "g2b4QUCpC", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-fNa5A", classNames)} style={{display: "contents"}}>
<Link href={{webPageId: "augiA20Il"}}><motion.a {...restProps} className={`${cx("framer-1eafk6o", className)} framer-gw83yi`} data-framer-name={"Logo"} layoutDependency={layoutDependency} layoutId={"g2b4QUCpC"} ref={ref} style={{opacity: 1, ...style}} transition={transition} variants={{"g2b4QUCpC-hover": {opacity: 0.5}, "g2b4QUCpC-pressed": {opacity: 0.5}}} {...addPropertyOverrides({"g2b4QUCpC-hover": {"data-framer-name": undefined}, "g2b4QUCpC-pressed": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "stretch", sizes: "141px", ...toResponsiveImage(pbrU38Tu3)}} className={"framer-1ldktlt"} data-framer-name={"SG Logo Text Only 1"} layoutDependency={layoutDependency} layoutId={"ILEgQyvIJ"} transition={transition}/></motion.a></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-fNa5A [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-fNa5A .framer-gw83yi { display: block; }", ".framer-fNa5A .framer-1eafk6o { height: 52px; overflow: visible; position: relative; text-decoration: none; width: 141px; }", ".framer-fNa5A .framer-1ldktlt { flex: none; height: 36px; left: 0px; position: absolute; top: calc(50.00000000000002% - 36px / 2); width: 141px; }", ".framer-fNa5A .framer-v-1eafk6o .framer-1eafk6o { cursor: pointer; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 52
 * @framerIntrinsicWidth 141
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"phumm_12K":{"layout":["fixed","fixed"]},"qc7uo7rgl":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"pbrU38Tu3":"image"}
 */
const FramerNcCc9GdHr: React.ComponentType<Props> = withCSS(Component, css, "framer-fNa5A") as typeof Component;
export default FramerNcCc9GdHr;

FramerNcCc9GdHr.displayName = "Logo Copy";

FramerNcCc9GdHr.defaultProps = {height: 52, width: 141};

addPropertyControls(FramerNcCc9GdHr, {pbrU38Tu3: {__defaultAssetReference: "data:framer/asset-reference,YCLJJn6g2ELAeTfIx44br9eGcM0.png?originalFilename=SG+Logo+Text+Only+1.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerNcCc9GdHr, [])